.navbar {
    justify-content: space-between;
    align-items: center;
    padding: 1.3rem 0.5rem 0.5rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    background-color: white;
    display: flex;
}

.navbar__logo {
    width: 70px;
    height: 40px;
    background-color: black;
}

.navbar__logo-img {
    width: 100%;
    height: 100%;
}

.navbar__toggler {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 24px;
}

.navbar__checkbox {
    position: absolute;
    top: 15px;
    right: 10px;
    width: 25px;
    height: 25px;
    z-index: 1;
    opacity: 0;
}

.navbar__checkbox:checked ~ .navbar__list {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    width: 200px;
    top: 90px;
}


.navbar__list {
    list-style-type: none;
    display: flex;
    z-index: 1;
}

.navbar__list--active {
    display: flex;
    flex-direction: column;
}

.navbar__list-item {
    margin-right: 1rem;
}

.navbar__list-item:last-child {
    margin-right: 0;
}

.navbar__link {
    padding: 0.5rem;
    display: block;
    color: black;
    text-decoration: none;
    border-radius: 5px;
}

.navbar__link--border-black {
    border: 1px solid black;
}

.navbar__link--border-red {
    border: 1px solid #F44336;
}

.navbar__link:hover {
    color: #ccc;
}

.navbar__submenu {
    position: relative;
}

.navbar__submenu-list {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    list-style-type: none;
    z-index: 99;
    min-width: 200px;
}

.navbar__submenu-list.navbar__submenu--active {
    display: block;
}

.navbar__submenu-btn {
    border: none;
    font-size: inherit;
    cursor: pointer;
    background-color: transparent;
    outline: none;
}

.navbar__flex {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.navbar__submenu-item {
    background-color: white;
    transition: all 0.3s;
}

.navbar__submenu-item:hover {
    background-color: #ccc;
}

.navbar__submenu-item .navbar__link {
    padding: 1rem;
    display: block;
}

.navbar__submenu-item .navbar__link:hover {
    background-color: #ddd;
}

.navbar__list-item .navbar__link {
    text-decoration: none;
    transition: all 0.3s;
}

.navbar__submenu-item .navbar__link {
    color: black;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar__list-item > .navbar__link:hover {
    color: #ccc;
}

.navbar__top,
.navbar__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar__top {
    margin-bottom: 3rem;
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
}

.overlay--active {
    width: 100%;
}

.overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
}

@media screen and (max-height: 450px) {
    .overlay a {
        font-size: 20px
    }

    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
}


@media screen and (min-width: 421px) {
    .navbar {
        display: flex;
        padding: 1rem 3rem;
    }

    .navbar__top,
    .navbar__bottom {
        width: 90%;
        margin: 0 auto;
    }

    .navbar__top {
        margin-bottom: 3rem;
    }
}

@media screen and (min-width: 600px) {
    .navbar__top,
    .navbar__bottom {
        width: 70%;
        margin: 0 auto;
    }

    .navbar__top {
        margin-bottom: 3rem;
    }

    .navbar__logo {
        width: 100px;
        height: 60px;
        background-color: black;
    }
}

@media screen and (min-width: 1100px) {
    .navbar__top,
    .navbar__bottom {
        width: 50%;
        margin: 0 auto;
    }

    .navbar__top {
        margin-bottom: 3rem;
    }
}