.coupon__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coupon__modal-content {
    height: 100vh !important;
}

.coupon__buttons > button {
    margin-bottom: 1rem;
}

.coupon__text-redeem {
    font-weight: bold;
    font-size: 20px;
}

@media screen and (min-width: 421px) {
    .coupon__buttons {
        flex-direction: row;
        justify-content: center;
    }

    .coupon__buttons > button {
        margin-right: 0.5rem;
    }

    .coupon__button--big {
        padding: 1rem 2rem;
        font-size: 17px;
    }

}

