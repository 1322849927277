.profile__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (min-width: 421px) {
    .profile__buttons {
        flex-direction: row;
    }
}