@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", serif;
}

html, body {
    height: 100%;
}

body {
    position: relative;
    background-color: #eee;
}

#root {
    height: 100%;
}


.position--relative {
    position: relative;
}

.line {
    margin: 1rem 0;
    background-color: #ccc;
}

.flex {
    display: flex !important;
}

.flex--justify-between {
    justify-content: space-between !important;
}

.flex--justify-center {
    justify-content: center !important;
}

.flex--column {
    flex-direction: column !important;
}

.flex--grow {
    flex-grow: 1;
}

.flex--align-center {
    align-items: center;
}

.flex--align-flex-start {
    align-items: flex-start;
}

.flex--wrap {
    flex-wrap: wrap;
}

.pa-1 {
    padding: 1rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-5 {
    margin-top: 5rem !important;
}

.pa-1 {
    padding: 1rem !important;
}

.pa-2 {
    padding: 2rem !important;
}

.pa-3 {
    padding: 3rem !important;
}

.pa-4 {
    padding: 4rem !important;
}

.pb-3 {
    padding-bottom: 3rem !important;
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}

.h-100 {
    height: 100% !important;
}

.center {
    display: flex;
    justify-content: center;
}

.block {
    display: block;
}

.text--center {
    text-align: center;
}

.text--white {
    color: white !important;
}

.text--red {
    color: #F44336 !important;
}

.text--black {
    color: black !important;
}

.text--uppercase {
    text-transform: uppercase;
}

.text--left {
    text-align: left !important;
}

.text--small {
    font-size: 12px;
}

.font--24 {
    font-size: 24px !important;
}

.overflow-y--auto {
    overflow-y: auto !important;
}

.grid {
    display: grid;
}

.grid--gap-1 {
    grid-gap: 1rem;
}

.badge {
    position: relative;
    padding: 10px;
    font-size: 12px;
    margin-right: 0.2rem;
    background-color: #1976D2;
}

.badge:last-child {
    margin-right: 0.2rem;
}

.badge__close {
    position: absolute;
    top: 2px;
    right: 5px;
    cursor: pointer;
    color: white;
}

.badge__content {
    color: white;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
}

.table td {
    padding: 0.5rem;
}

.table thead td {
    font-weight: bold;
}


.confirm-dialog {

}

@media screen and (min-width: 421px) {


}
