.share__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.share__buttons > button {
    margin-bottom: 1rem;
}

@media screen and (min-width: 421px) {
    .share__buttons {
        flex-direction: row;
    }

    .share__buttons > button {
        margin-bottom: 0;
        margin-right: 0.5rem;
    }
}