.page,
.page--50 {
    margin: 1rem;
    height: 100vh;
    overflow-y: auto;
}

.page-fluid {
    padding: 0;
}

.page--center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.page__title {
    font-size: 28px;
    font-family: "Spartan Bold", sans-serif;
    margin-bottom: 1rem;
}

.page__subtitle {
    font-size: 18px;
    font-weight: lighter;
    margin-bottom: 1rem;
    text-align: center;
}

.page__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.page__content--desktop {
    position: absolute;
    width: 60%;
    height: 100%;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: auto;
}

@media screen and (min-width: 421px) {
    .page {
        padding: 2rem;
    }

    .page-fluid {
        padding: 2rem;
        position: relative;
    }

    .page--50 {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (min-width: 700px) {
    .page--50 {
        width: 70%;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1100px) {
    .page--50 {
        width: 50%;
        margin: 0 auto;
    }
}