@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css);
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", serif;
}

html, body {
    height: 100%;
}

body {
    position: relative;
    background-color: #eee;
}

#root {
    height: 100%;
}


.position--relative {
    position: relative;
}

.line {
    margin: 1rem 0;
    background-color: #ccc;
}

.flex {
    display: flex !important;
}

.flex--justify-between {
    justify-content: space-between !important;
}

.flex--justify-center {
    justify-content: center !important;
}

.flex--column {
    flex-direction: column !important;
}

.flex--grow {
    flex-grow: 1;
}

.flex--align-center {
    align-items: center;
}

.flex--align-flex-start {
    align-items: flex-start;
}

.flex--wrap {
    flex-wrap: wrap;
}

.pa-1 {
    padding: 1rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-5 {
    margin-top: 5rem !important;
}

.pa-1 {
    padding: 1rem !important;
}

.pa-2 {
    padding: 2rem !important;
}

.pa-3 {
    padding: 3rem !important;
}

.pa-4 {
    padding: 4rem !important;
}

.pb-3 {
    padding-bottom: 3rem !important;
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}

.h-100 {
    height: 100% !important;
}

.center {
    display: flex;
    justify-content: center;
}

.block {
    display: block;
}

.text--center {
    text-align: center;
}

.text--white {
    color: white !important;
}

.text--red {
    color: #F44336 !important;
}

.text--black {
    color: black !important;
}

.text--uppercase {
    text-transform: uppercase;
}

.text--left {
    text-align: left !important;
}

.text--small {
    font-size: 12px;
}

.font--24 {
    font-size: 24px !important;
}

.overflow-y--auto {
    overflow-y: auto !important;
}

.grid {
    display: grid;
}

.grid--gap-1 {
    grid-gap: 1rem;
}

.badge {
    position: relative;
    padding: 10px;
    font-size: 12px;
    margin-right: 0.2rem;
    background-color: #1976D2;
}

.badge:last-child {
    margin-right: 0.2rem;
}

.badge__close {
    position: absolute;
    top: 2px;
    right: 5px;
    cursor: pointer;
    color: white;
}

.badge__content {
    color: white;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
}

.table td {
    padding: 0.5rem;
}

.table thead td {
    font-weight: bold;
}


.confirm-dialog {

}

@media screen and (min-width: 421px) {


}

.row {
    display: flex;
}

.col--1 {
    width: 8.33%;
}

.col--2 {
    width: 16.66%;
}

.col--3 {
    width: 25% !important;
}

.col--4 {
    width: 33.33%;
}

.col--5 {
    width: 41.66%;
}

.col--6 {
    width: 50%;
}

.col--7 {
    width: 58.33%;
}

.col--8 {
    width: 66.66%;
}

.col--9 {
    width: 75%;
}

.col--10 {
    width: 83.33%;
}

.col--11 {
    width: 91.66%;
}

.col--12 {
    width: 100%;
}
.list {
    list-style-type: none;
    margin-bottom: 1rem;
}

.list__item {
    border: 1px solid #ccc;
    border-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list__item:last-child {
    border-bottom: 1px solid #ccc;
}

.list__link {
    color: black;
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 1rem;
    display: block;
    width: 100%;
    text-align: left;
}

.list__link:hover {
    color: #ccc;
}
.form {
    width: 60%;
    margin: 0 auto;
}

.form__group {
    width: 100%;
    text-align: left;
}

.form__input {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    outline: none;
    width: 100%;
    background-color: transparent !important;
    -webkit-appearance: none;
}

.form__input::placeholder {
    color: #ccc;
}

.form__btn {
    padding: 10px 30px;
    border: 1px solid #ccc;
    cursor: pointer;
    font-size: 14px;
}

.form__btn:hover {
    background-color: #ccc;
    border: 1px solid black;
}

.form__buttons {
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.form__buttons__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.form__textarea {
    height: 150px;
    padding: 10px;
    resize: none;
    outline: none;
    border: 1px solid #ccc;
    width: 100%;
}

.form__select {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    -webkit-appearance: none;
    margin-bottom: 1rem;
}

.form__label {
    margin-bottom: 0.5rem;
    font-size: 12px;
    width: 100%;
    text-transform: uppercase;
    display: block;
}

.form__label--required::after {
    content: "* påkrævet";
    color: darkred;
    margin-left: 0.5rem;
    font-size: 10px;
}

.form__textarea::placeholder {
    color: #ccc;
}

.form__buttons {
    margin-bottom: 1rem;
}

@media screen and (min-width: 421px) {
    .form__input {
        border: 1px solid #ccc;
        padding: 10px;
    }

    .form__buttons {
        flex-direction: row;
        display: flex;
        align-items: flex-start;
        margin-top: 1rem;
        justify-content: space-between;
    }

    .form__buttons__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
    }
}

.page,
.page--50 {
    margin: 1rem;
    height: 100vh;
    overflow-y: auto;
}

.page-fluid {
    padding: 0;
}

.page--center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.page__title {
    font-size: 28px;
    font-family: "Spartan Bold", sans-serif;
    margin-bottom: 1rem;
}

.page__subtitle {
    font-size: 18px;
    font-weight: lighter;
    margin-bottom: 1rem;
    text-align: center;
}

.page__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.page__content--desktop {
    position: absolute;
    width: 60%;
    height: 100%;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: auto;
}

@media screen and (min-width: 421px) {
    .page {
        padding: 2rem;
    }

    .page-fluid {
        padding: 2rem;
        position: relative;
    }

    .page--50 {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (min-width: 700px) {
    .page--50 {
        width: 70%;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1100px) {
    .page--50 {
        width: 50%;
        margin: 0 auto;
    }
}
.coupons {

}

.coupon {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    box-shadow: 1px 5px 7px -2px #666;
    border-radius: 10px;
}

.coupon__img {
    display: block;
    height: 190px;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
}

.coupon__favorite {
    color: #f52f5e;
    font-size: 26px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 2;
}

.coupon__redeemed {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
    border-radius: 10px;
}

.coupon__redeemed-label {
    position: absolute;
    top: 45px;
    padding: 10px 25px;
    background-color: #09e284;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    color: white;
}

.coupon__redeemed-code {
    color: white;
    margin-top: 125px;
    margin-left: 10px;
    display: block;
}

.coupon__redeemed-date {
    margin-top: 0.5rem;
    margin-left: 10px;
    color: white;
}

@media screen and (min-width: 921px) {
    .coupons {
        width: 70%;
        margin: 0 auto;
    }

    .coupon__img {
        height: 270px;
    }
}

.pagination {
    display: flex;
    align-items: center;
    list-style-type: none;
}

.pagination li {
    margin-right: 0.5rem;
    border: 1px solid #ccc;
    padding: 0.5rem;
    cursor: pointer;
}

.pagination li a {
    outline: none;
}

.pagination li:last-child {
    margin-right: 0;
}

.pagination .disabled {
    cursor: not-allowed;
}

.pagination .active {
    font-weight: bold;
    background-color: #ccc;
}

.navbar {
    justify-content: space-between;
    align-items: center;
    padding: 1.3rem 0.5rem 0.5rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    background-color: white;
    display: flex;
}

.navbar__logo {
    width: 70px;
    height: 40px;
    background-color: black;
}

.navbar__logo-img {
    width: 100%;
    height: 100%;
}

.navbar__toggler {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 24px;
}

.navbar__checkbox {
    position: absolute;
    top: 15px;
    right: 10px;
    width: 25px;
    height: 25px;
    z-index: 1;
    opacity: 0;
}

.navbar__checkbox:checked ~ .navbar__list {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    width: 200px;
    top: 90px;
}


.navbar__list {
    list-style-type: none;
    display: flex;
    z-index: 1;
}

.navbar__list--active {
    display: flex;
    flex-direction: column;
}

.navbar__list-item {
    margin-right: 1rem;
}

.navbar__list-item:last-child {
    margin-right: 0;
}

.navbar__link {
    padding: 0.5rem;
    display: block;
    color: black;
    text-decoration: none;
    border-radius: 5px;
}

.navbar__link--border-black {
    border: 1px solid black;
}

.navbar__link--border-red {
    border: 1px solid #F44336;
}

.navbar__link:hover {
    color: #ccc;
}

.navbar__submenu {
    position: relative;
}

.navbar__submenu-list {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    list-style-type: none;
    z-index: 99;
    min-width: 200px;
}

.navbar__submenu-list.navbar__submenu--active {
    display: block;
}

.navbar__submenu-btn {
    border: none;
    font-size: inherit;
    cursor: pointer;
    background-color: transparent;
    outline: none;
}

.navbar__flex {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.navbar__submenu-item {
    background-color: white;
    transition: all 0.3s;
}

.navbar__submenu-item:hover {
    background-color: #ccc;
}

.navbar__submenu-item .navbar__link {
    padding: 1rem;
    display: block;
}

.navbar__submenu-item .navbar__link:hover {
    background-color: #ddd;
}

.navbar__list-item .navbar__link {
    text-decoration: none;
    transition: all 0.3s;
}

.navbar__submenu-item .navbar__link {
    color: black;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar__list-item > .navbar__link:hover {
    color: #ccc;
}

.navbar__top,
.navbar__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar__top {
    margin-bottom: 3rem;
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
}

.overlay--active {
    width: 100%;
}

.overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
}

@media screen and (max-height: 450px) {
    .overlay a {
        font-size: 20px
    }

    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
}


@media screen and (min-width: 421px) {
    .navbar {
        display: flex;
        padding: 1rem 3rem;
    }

    .navbar__top,
    .navbar__bottom {
        width: 90%;
        margin: 0 auto;
    }

    .navbar__top {
        margin-bottom: 3rem;
    }
}

@media screen and (min-width: 600px) {
    .navbar__top,
    .navbar__bottom {
        width: 70%;
        margin: 0 auto;
    }

    .navbar__top {
        margin-bottom: 3rem;
    }

    .navbar__logo {
        width: 100px;
        height: 60px;
        background-color: black;
    }
}

@media screen and (min-width: 1100px) {
    .navbar__top,
    .navbar__bottom {
        width: 50%;
        margin: 0 auto;
    }

    .navbar__top {
        margin-bottom: 3rem;
    }
}
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100vh; /* Full height */
    overflow-y: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal__content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow-y: auto;
    height: 100vh;
}

.modal__content--down {
    bottom: -40px;
    top: auto;
}

.modal__content--down::after {
    content: "";
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    left: 50%;
}

/* The Close Button */
.modal__close {
    color: #aaaaaa;
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
}

.modal__close:hover,
.modal__close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .modal {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .modal__content {
        width: 90%;
    }
}

@media screen and (min-height: 500px) {
    .modal__content {
        height: auto;
        max-height: 90%;
    }
}

@media screen and (min-height: 700px) {
    .modal__content {
        height: auto;
        max-height: 80%;
    }
}

.share__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.share__buttons > button {
    margin-bottom: 1rem;
}

@media screen and (min-width: 421px) {
    .share__buttons {
        flex-direction: row;
    }

    .share__buttons > button {
        margin-bottom: 0;
        margin-right: 0.5rem;
    }
}
.btn {
    outline: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    padding: 10px 35px;
    border-radius: 10px;
    color: white;
    border: none;
    text-transform: uppercase;
    line-height: 2;
    position: relative;
    text-decoration: none;
    font-size: 14px;
}

.btn--plain {
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.btn--white {
    background-color: white;
}

.btn--primary {
    background-color: #eee;
    color: black !important;
}

.btn--success {
    background-color: #388E3C;
    color: white !important;
}

.btn--error {
    background-color: #F44336;
    color: white !important;
}

.btn--primary:hover {
    background-color: #ccc;
}

.btn--small {
    padding: 5px 15px 2px !important;
    font-size: 10px !important;
}

.btn:disabled {
    background-color: #eee;
    color: #333;
    cursor: not-allowed;
}

.btn > .fa {
    position: absolute;
    left: 15px;
    top: 15px;
}
.form-errors {
    padding: 1rem 2rem;
    border: darkred;
    background-color: pink;
    margin-bottom: 1rem;
    text-align: left;
    width: 100%;
    font-size: 14px;
}

.form-errors__title {
    font-size: 14px;
    background-color: white;
    padding: 10px;
    margin-bottom: 1rem;
}
.login__register-btn {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border: none;
}
.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5); /*dim the background*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }
    50%, 100% {
        top: 24px;
        height: 32px;
    }
}

.alert {
    padding: 1rem;
    text-align: left;
    margin-bottom: 1rem;
}

.alert--success {
    border-left: 5px solid #388E3C;
    background-color: #8BC34A;
    color: white;
}

.alert--error {
    border-left: 5px solid #D32F2F;
    background-color: #F44336;
    color: white;
}

.alert--primary {
    border-left: 5px solid #1976D2;
    background-color: #03A9F4;
    color: white;
}
.td-only-desktop {
    display: none;
}

@media screen and (min-width: 421px) {
    .td-only-desktop {
        display: table-cell;
    }
}

.autocomplete {
    position: relative;
}

.autocomplete__container {
    position: relative;
    z-index: 1;
}

.autocomplete__results {
    position: absolute;
    top: 5px;
    left: 0;
    width: 300px;
    max-height: 250px;
    overflow-y: auto;
    list-style-type: none;
    border: 1px solid #ccc;
    display: none;
    background-color: white;
}

.autocomplete__selected {
    border: 1px solid #388E3C;
}

.autocomplete__results--active {
    display: block;
}

.autocomplete__result {
    font-size: 14px;
    transition: all 0.3s;
    cursor: pointer;
    padding: 1rem;
}

.autocomplete__result--active {
    background-color: #eee;
}

.autocomplete__buttons {
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
}

.autocomplete__buttons-btn {
    margin-right: 0.3rem;

}

.autocomplete__buttons-btn:last-child {
    margin-right: 0;
}
.coupon__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coupon__modal-content {
    height: 100vh !important;
}

.coupon__buttons > button {
    margin-bottom: 1rem;
}

.coupon__text-redeem {
    font-weight: bold;
    font-size: 20px;
}

@media screen and (min-width: 421px) {
    .coupon__buttons {
        flex-direction: row;
        justify-content: center;
    }

    .coupon__buttons > button {
        margin-right: 0.5rem;
    }

    .coupon__button--big {
        padding: 1rem 2rem;
        font-size: 17px;
    }

}


.profile__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (min-width: 421px) {
    .profile__buttons {
        flex-direction: row;
    }
}
.change-password {

}

.change-password__title {
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 1rem;
}
.change-email {

}

.change-email__title {
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 1rem;
}
